import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image/withIEPolyfill";

import Gallery from "root/components/Gallery";

import "./index.css";
import withQuery from "./withQuery";

function HomeHero({ data }) {
  const { edges } = data.allFile;
  const backgrounds = edges.map(edge => {
    return (
      <div styleName="background">
        <Img fluid={edge.node.image.fluid} />
      </div>
    );
  });

  return (
    <div styleName="root">
      <Gallery slides={backgrounds} />
    </div>
  );
}

HomeHero.propTypes = {
  data: PropTypes.shape({
    allFile: PropTypes.shape({
      edges: PropTypes.array.isRequired,
    }).isRequired,
  }).isRequired,
};

export default withQuery(HomeHero);
