import React from "react";

import Text from "root/components/Text";
import Arrow from "root/components/Arrow";

import "./index.css";

function renderService(id, title, description) {
  return (
    <a href={`/services#${id}`} styleName="service-wrapper">
      <div styleName="service">
        <div styleName="copy">
          <Text
            variant="h2"
            weight="medium"
            color="grey"
            fontFamily="proxima-nova"
          >
            {title}
          </Text>
          <Text color="grey" fontFamily="proxima-nova">
            {description}
          </Text>
        </div>
        <div styleName="arrow">
          <Arrow direction="right" />
        </div>
      </div>
    </a>
  );
}

export default function HomeServices() {
  return (
    <div styleName="root">
      <div styleName="content">
        {renderService("epi", "EPI", "Eletrólise Percutânea Intratecidual")}
        {renderService("prp", "PRP's", "Fatores de Crescimento")}
        {renderService(
          "viscosuplementacao",
          "Viscosuplementação",
          "Tratamento de artrite",
        )}
      </div>
    </div>
  );
}
