import React from "react";
import { StaticQuery, graphql } from "gatsby";

const query = graphql`
  query {
    hero1: file(relativePath: { eq: "images/hero1.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 2160, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    hero2: file(relativePath: { eq: "images/hero2.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 2160, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    hero3: file(relativePath: { eq: "images/hero3.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 2160, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default Component => () => (
  <StaticQuery query={query} render={data => <Component data={data} />} />
);
