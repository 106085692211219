import React from "react";
import PropTypes from "prop-types";

import "./index.css";

function Arrow({ direction }) {
  const style = direction === "right" ? { transform: "rotate(180deg)" } : null;

  return (
    <div styleName="root">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 23.09 16.35"
        width="23.09"
        height="16.35"
        style={style}
      >
        <g id="g6" transform="translate(-27.91,-30.36)">
          <polyline
            points="36.61 30.36 27.91 38.34 37.04 46.71"
            id="polyline2"
          />
          <line x1="51" x2="28" y1="38.5" y2="38.5" id="line4" />
        </g>
      </svg>
    </div>
  );
}

Arrow.propTypes = {
  direction: PropTypes.oneOf(["left", "right"]),
};

Arrow.defaultProps = {
  direction: "left",
};

export default Arrow;
