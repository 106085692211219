import React from "react";

import Section from "root/components/Section";
import Text from "root/components/Text";
import Gallery from "root/components/Gallery";

import "./index.css";

const TESTIMONIES = [
  {
    text:
      '"Fiz um tratamento aos esporões e fiquei ótima! Recomendo, são excelentes profissionais."',
    name: "Conceição Cruz",
  },
  {
    text:
      '"Tinha um problema na anca que me provocava dores horríveis. O tratamento de fisioterapia aqui realizado devolveu-me a qualidade de vida! Recomendo vivamente!"',
    name: "Elsa Moura",
  },
  {
    text:
      '"Extremamente eficientes! Tinha uma fasceíte plantar há cerca de 6 meses e já tinha feito diversos tratamentos sem resultado. Em 4 sessões fiquei completamente assintomática. Recomendo!"',
    name: "Paula Pereira",
  },
];

export default function HomeQuote() {
  const backgrounds = TESTIMONIES.map(testimony => {
    return (
      <div styleName="content">
        <Text variant="h2" weight="medium">
          {testimony.text}
        </Text>

        <Text>{testimony.name}</Text>
      </div>
    );
  });

  return (
    <Section>
      <div styleName="root">
        <Gallery slides={backgrounds} />
      </div>
    </Section>
  );
}
