import React from "react";
import PropTypes from "prop-types";

import Section from "root/components/Section";
import Background from "root/components/Background";
import Gallery from "root/components/Gallery";
import Header from "root/components/Header";
import Text from "root/components/Text";
import Button from "root/components/Button";
import BackgroundImage from "root/components/BackgroundImage";
import Facebook from "root/assets/vectors/facebook.inline.svg";
import Instagram from "root/assets/vectors/instagram.inline.svg";
import Scroll from "root/assets/vectors/scroll.inline.svg";

import withQuery from "./withQuery";

import "./index.css";

const SLIDES = [
  {
    key: "hero1",
    background: "hero1",
    copy: "Fisioterapia e Osteopatia",
    link: "fisioterapia",
  },
  {
    key: "hero2",
    background: "hero2",
    copy: "Medicina Anti-Aging",
    link: "antiaging",
  },
  {
    key: "hero3",
    background: "hero3",
    copy: "Medicina Regenerativa",
    link: "medicinaregenerativa",
  },
];

function renderBackgrounds(data) {
  return SLIDES.map(slide => (
    <div styleName="background">
      <BackgroundImage key={slide.key} image={data[slide.background].image}>
        <div styleName="title">
          <Text variant="h1" fontFamily="open-sans-cond">
            {slide.copy}
          </Text>

          <a href={`/services#${slide.link}`}>
            <Text fontFamily="open-sans-cond">
              <u>Descubra as Vantagens</u>
            </Text>
          </a>
        </div>
      </BackgroundImage>
    </div>
  ));
}

function HomeHero({ data }) {
  return (
    <>
      <Background
        backgroundChildren={
          <Gallery style={{ zIndex: 1 }} slides={renderBackgrounds(data)} />
        }
      >
        <Section>
          <div styleName="root">
            <div styleName="content">
              <Header currentPage="/" />

              <div styleName="footer">
                <Scroll />

                <div styleName="follow-us">
                  <Text variant="small">SEGUE-NOS</Text>
                  <a
                    href="https://www.facebook.com/Osteovida-531121787095765/"
                    aria-label="Instagram"
                  >
                    <Facebook />
                  </a>
                  <a
                    href="https://www.instagram.com/osteovida/"
                    aria-label="Instagram"
                  >
                    <Instagram />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Section>
      </Background>

      <div styleName="mobile-footer">
        <div styleName="contact-us">
          <a href="#contactform">
            <Button>MARCA A TUA CONSULTA</Button>
          </a>
        </div>

        <div styleName="follow-us">
          <Text variant="small" color="grey" weight="bold">
            SEGUE-NOS
          </Text>

          <div styleName="social">
            <Facebook />
            <Instagram />
          </div>
        </div>
      </div>
    </>
  );
}

HomeHero.propTypes = {
  data: PropTypes.object.isRequired,
};

export default withQuery(HomeHero);
