import React from "react";

import SEO from "root/components/SEO";
import Layout from "root/components/Layout";
import HomeHero from "root/components/sections/HomeHero";
import HomeAbout from "root/components/sections/HomeAbout";
import HomeServices from "root/components/sections/HomeServices";
import HomeSpace from "root/components/sections/HomeSpace";
import HomeQuote from "root/components/sections/HomeQuote";
import HomeContactForm from "root/components/sections/HomeContactForm";
import HomeContactInfo from "root/components/sections/HomeContactInfo";
import Footer from "root/components/Footer";

const IndexPage = () => (
  <Layout>
    <SEO title="Osteovida" />
    <HomeHero />
    <HomeAbout />
    <HomeServices />
    <HomeSpace />
    <HomeQuote />
    <HomeContactForm />
    <HomeContactInfo />
    <Footer />
  </Layout>
);

export default IndexPage;
