import React from "react";
import { StaticQuery, graphql } from "gatsby";

const query = graphql`
  query {
    allFile(
      filter: { relativePath: { regex: "/home-space/" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          image: childImageSharp {
            fluid(maxWidth: 2160, quality: 95) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

export default Component => () => (
  <StaticQuery query={query} render={data => <Component data={data} />} />
);
