import React from "react";

import Section from "root/components/Section";
import Text from "root/components/Text";
import Link from "root/components/Link";

import "./index.css";

export default function HomeAbout() {
  return (
    <div styleName="root">
      <Section>
        <div styleName="content">
          <div styleName="title">
            <Text variant="h1" color="grey" fontFamily="open-sans-cond">
              A sua saúde em boas mãos
            </Text>
          </div>

          <div styleName="copy">
            <Text color="light-grey">
              Dispomos de tratamentos e tecnologia de ponta para ajudar na
              reabilitação e prevenção das mais variadas patologias.
            </Text>
          </div>

          <div styleName="link">
            <Text color="grey">
              <Link to="/services" underlineColor="teal">
                Ver todos os serviços
              </Link>
            </Text>
          </div>
        </div>
      </Section>
    </div>
  );
}
